export const BUTTONS = {
  IMPORT: {
    label: 'Import',
    backgroundColor: 'default',
  },
  EXPORT: {
    label: 'Export',
    backgroundColor: 'default',
  },
  BULK_UPDATE: {
    label: 'Bulk Update',
    backgroundColor: 'default',
  },
  ADD: {
    label: 'Add',
    backgroundColor: 'default',
  },
  DELETE: {
    label: 'Delete',
    backgroundColor: 'default',
  },
  COPY: {
    label: 'Copy',
    backgroundColor: 'default',
  },
  SAVE: {
    label: 'Save',
    backgroundColor: 'default',
  },
  RESET: {
    label: 'Reset',
    backgroundColor: 'default',
  },
};

export const FILE_UPLOAD_ROW_COUNT_ERROR = 1000;
export const FILE_UPLOAD_ROW_COUNT_ERROR_MESSAGE =
  'Invalid file. Exceeds 1000 rows';
export const FILE_UPLOAD_ASSET_ID_MISSING_MESSAGE =
  'Invalid file. Column  ID is missing';
export const FILE_UPLOAD_EMPTY_FILE_MESSAGE =
  'Invalid file. No records available';

const domain = 'https://addproapi.inspirepro.co.in/api';
//const domain = 'http://localhost:5000/api';

export const DATAURLS = {
  ASSETS: { url: `${domain}/assets` },
  SINGLE_SSETS: { url: `${domain}/assets/singleAggrement` },
  ASSETSREPORT: { url: `${domain}/assets/report` },
  ASSETS_ALL: { url: `${domain}/assets/all` },
  ASSETS_MULTIPLE: { url: `${domain}/assets/multiple` },
  COLUMNDEFINITIONS: {
    url: `${domain}/assets/columnDefinitions`,
  },
  ASSETTYPES: { url: `${domain}/assetTypes` },
  PALLETS: { url: `${domain}/pallets` },
  PALLETS_COLUMNDEFINITIONS: {
    url: `${domain}/pallets/columnDefinitions`,
  },
  PALLETS_STATUS_CODES: {
    url: `${domain}/pallets/statusCodes`,
  },
  PALLETS_IN_PRODUCTION: {
    url: `${domain}/pallets/inproduction`,
  },
  PICEA: { url: `${domain}/picea` },
  PICEA_COLUMNDEFINITIONS: {
    url: `${domain}/picea/columnDefinitions`,
  },
  CERTUS: { url: `${domain}/certus` },
  CERTUS_COLUMNDEFINITIONS: {
    url: `${domain}/certus/columnDefinitions`,
  },
  PICEA_DIRECT: {},
  STATUS_CODES: {
    url: `${domain}/statuscodes`,
  },
  USERS: { url: `${domain}/users` },
  USERS_COLUMNDEFINITIONS: { url: `${domain}/users/columnDefinitions` },
  USER_ROLES: { url: `${domain}/users/roles` },
  LOGIN: { url: `${domain}/users/login` },
  AUTHORIZE: { url: `${domain}/users/authorize` },
};
