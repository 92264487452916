import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import * as _ from 'underscore';


import * as moment from 'moment';

import XLSX from 'xlsx';


import { useState, useEffect, useContext, useRef } from 'react';

import { AgGridReact, AgGridColumn } from 'ag-grid-react';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

import ActionCellRenderer from '../components/ActionCellRenderer';
import CurrencyEditor from '../components/CurrencyEditor';
import CustomDropdown from '../components/CustomDropdown';
import DialogEditor from '../components/DialogEditor';
import FileUploadDialog from '../components/FileUploadDialog';
import AggrementListDialog from '../components/AggrementListDialog';
import NumericEditor from '../components/NumericEditor';
import AppContext from '../context/AppContext';
import { AppTheme } from '../utilities/Theme';
import { DATAURLS } from '../utilities/constants';
import {
  fetchGet,
} from '../utilities/dataCalls';
import { generateExcel } from '../utilities/generateExcel';


const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '95vw',
      height: '93vh',
      marginLeft: '4vw',
    },

    buttonBox: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '50%',
    },
    buttonArea: {
      display: 'flex',
    },
    select: {
      color: 'white',
    },
    sectionHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      // color: '#212121',
      color: 'white',
      fontWeight: 'bold',
      fontSize: '1rem',
      // marginTop: '20px',
      width: '95%',
      height: '40px',
      boxShadow: '0px 0px 5px #222',
      paddingLeft: '10px',
      background:
      'linear-gradient(90deg, rgba(0,165,145,1) 55%, rgba(0,165,145,1) 100%)',
    },
    aggrementValue: {
      textDecoration: 'none',
      color: '#000',
      cursor: 'pointer'
    },
    actionArea: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-evenly',
      width: '30%',
    },
    actionIconDisabled: {
      color: '#aaa',
      cursor: 'not-allowed',
    },
    actionIcon: {
      fontSize: '1rem',
      cursor: 'pointer',
      color: 'white',
    },

    textRoot: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: 400,
      height: '30px',
    },
    input: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
  })
);

const frameworkComponents = {
  //   CustomCellEditor: CustomCellEditor,
  ActionCellRenderer,
  NumericEditor,
  CurrencyEditor,
  DialogEditor,
};

const Assets = () => {
  const theme = useTheme(AppTheme);
  const classes = useStyles(theme);
  const appContext = useContext(AppContext);
  const overlayLoadingTemplate =
    '<span class="ag-overlay-loading-center">Please wait while update in progress</span>';

  const buildColumnDefinitions = (columnDefs) => {
    return columnDefs.map((columnDef, index) => {
      let columnDefinition = {
        headerName: columnDef.header_name ,
        // cellRenderer: index === 0 ? false : false,
        headerCheckboxSelection: false,
        headerCheckboxSelectionFilteredOnly: false,
        checkboxSelection: false,
        field: columnDef.field,
        editable: false,
        filter: 'agTextColumnFilter',
        sortable: true,
        resizable: true,
        hide: columnDef.hide,
        width: 200,
        columnType: columnDef.type,
        // valueFormatter: (params) => {
        //   console.log(params.value, 'columnDef.type', columnDef.type)
        //   if (columnDef.type === 'date' && params.value) {
        //     return params.value ? moment(params.value).format('YY-MM-DD') : '-';
        //   }
        //   if (columnDef.type === 'currencyColumn') {
        //     return params.value > 0 ? numberWithCommas(params.value) + ' kr' : '0';
        //   }
        // },
        cellRenderer: (params) => {
          // console.log(" params *****", params.colDef);

          if (params.colDef && params.colDef.columnType === 'date') {
            return params.value ? moment(params.value).format('YY-MM-DD') : '-';
          }
          if (params.colDef && params.colDef.columnType === 'currencyColumn') {
            params.value = params.value > 0 ? numberWithCommas(params.value) + ' kr' : '0';
          }
          var link = document.createElement('a');
          link.href = '#';
          link.innerText = params.value;
          link.className=classes.aggrementValue;
          link.addEventListener('click', (e) => {
              e.preventDefault();
              showAggrement(params.data.leasing_agreement_no)
          });
          return link;
        }
      };
      return columnDefinition;
    });
  };
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }
  const [loading, setLoading] = useState(true);
  const [pageSize, setPageSize] = useState(25);
  const [leaseAggrementNo, setLeaseAggrementNo] = useState();
  
  const [rowData, setRowData] = useState([]);
  const [rowDataAPI, setRowDataAPI] = useState([]);
  const [columnDefs, setColumnDefs] = useState([
    {
      "header_name": "Agreement",
      "field": "leasing_agreement_no",
      "editable": false,
      "type": null,
      "order_by_id": 1
    }, {
      "header_name": "Client",
      "field": "customer_name",
      "editable": false,
      "type": null,
      "hide": false,
      "order_by_id": 2
    }, {
      "header_name": "Hyra per månad",
      "field": "lease_amt",
      "editable": true,
      "type": 'currencyColumn',
      "hide": false,
      "order_by_id": 3,
    },
    {
      "header_name": "Fakturerat Belopp",
      "field": "invoiced_amount",
      "editable": true,
      "type": 'currencyColumn',
      "hide": false,
      "order_by_id": 4,
    },
    {
      "header_name": "Slutdatum",
      "field": "end_date",
      "editable": true,
      "type": 'date',
      "hide": false,
      "order_by_id": 5
    },
  ]);
  const [aggrementOpen, setAggrementOpen] = useState(false);
  const [aggrementPageOpen, setggrementPageOpen] = useState(false);

  const [assetTypes, setAssetTypes] = useState([]);
  const [assetTypeFieldMapping, setAssetTypeFieldMapping] = useState([]);
  const [statusCodes, setStatusCodes] = useState([]);
  const [statusNames, setStatusNames] = useState([]);
  const [palletNumbers, setPalletNumbers] = useState([]);
  const [selectedAssetType, setSelectedAssetType] = useState('All');
  const [bulkUpdateOpen, setBulkUpdateOpen] = useState(false);
  const [fileUploadOpen, setFileUploadOpen] = useState(false);
  const [enableBulkUpdates, setEnableBulkUpdates] = useState(false);
  const [gridApi, setGridApi] = useState();
  const [quickFilterText, setQuickFilterText] = useState('');
  const externalFilterRef = useRef(null);

  useEffect(() => {
    gridApi && gridApi.onFilterChanged();
  }, [selectedAssetType]);

  const showAggrement = (aggrementNo) => {
    if (aggrementNo) {
      setAggrementOpen(true);
      setLeaseAggrementNo(aggrementNo)
    }
  }

  useEffect(() => {
    setLoading(false);
  }, [rowData]);

  useEffect(() => {
    if (!gridApi) {
      return;
    }

    loading ? gridApi.showLoadingOverlay() : gridApi.hideOverlay();
  }, [loading]);

  const onModelUpdated = (params) => {
    // params.api.sizeColumnsToFit();
  };
  
  const getNewData = async (gridApi) => {
    setLoading(true);
    gridApi.showLoadingOverlay();

    fetchGet(DATAURLS.ASSETSREPORT.url, appContext.token)
    .then((response) => {
      setRowData(response.reports);
      // setLoading(false);
      // let tempAPI = JSON.parse(JSON.stringify(response.assets));
      // setRowDataAPI(tempAPI);
    })
    .catch((err) => {
      throw err;
    });
    highlightUnsavedRows();
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
    getNewData(params.api);
  };

  const highlightUnsavedRows = (params) => {
    if (!params || rowDataAPI.length === 0) {
      return;
    }
    let missingRowNodes = params.api.rowModel.rowsToDisplay.filter((row) => {
      if (!row.data.asset_id) {
        return row;
      }
    });

    if (missingRowNodes.length > 0) {
      missingRowNodes.map((node) => {
        if (params.node !== node) {
          node.setSelected(true);
        }
      });
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.sectionHeader}>
        Report
      </div>
      <div
        className='ag-theme-balham'
        style={{
          width: '95%',
          height: '80vh',
          boxShadow: '0 1px 15px 1px rgba(69,65,78,.08)',
        }}
      >
         <AgGridReact
          rowData={rowData}
          rowBuffer={500}
          debounceVerticalScrollbar={true}
          columnDefs={buildColumnDefinitions(columnDefs)}
          frameworkComponents={frameworkComponents}
          suppressDragLeaveHidesColumns={true}
          onGridReady={onGridReady}
          overlayLoadingTemplate={overlayLoadingTemplate}
          getNewData={getNewData}
          pagination={true}
          enableCellTextSelection={true}
          paginationPageSize={pageSize}
          suppressRowClickSelection={true}
          alwaysShowVerticalScroll={true}
          quickFilterText={quickFilterText}
          suppressFieldDotNotation={true}
          floatingFilter={true}
        ></AgGridReact>
        <CustomDropdown
          options={[25, 50, 100, 500]}
          title={'Page Size'}
          value={pageSize}
          onChange={(value) => {
            setPageSize(value);
            gridApi.paginationSetPageSize(value);
          }}
        />
      </div>
      <FileUploadDialog
        open={fileUploadOpen}
        allAssets={rowData}
        title='file upload'
        setOpen={setFileUploadOpen}
        assetTypes={assetTypes}
        palletNumbers={palletNumbers}
        statusNames={statusNames}
        getNewData={getNewData}
        parentGridApi={gridApi}
      />
      <AggrementListDialog
        open={aggrementOpen}
        allAssets={rowData}
        title='Aggrement List'
        setOpen={setAggrementOpen}
        getNewData={getNewData}
        leaseAggrementNo={leaseAggrementNo}
      />
    </div>
  );
};

export default Assets;
