import React from 'react'
import Button from 'react-bootstrap/Button';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { makeStyles, createStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) =>
createStyles({
  even: {
          background: '#ecf0f1'
      },
  bg: {
      background: '#00a591'
  },
  button: {
      background: '#00a591',
      color: '#fff',
      padding: '5px',
      borderRadius: '8px',
      marginBottom: '10px'
  }
})
);
 const ExportCSV = ({csvData, fileName}) => {
    const classes = useStyles();
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToCSV = (csvData, fileName) => {
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { 'stock': ws }, SheetNames: ['stock'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    return (
        <Button className={classes.button} variant="warning" onClick={(e) => exportToCSV(csvData,fileName)}>Export Excel</Button>
    )
}
export default ExportCSV