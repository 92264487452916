import {
  faTimes,
  faFileUpload,
  faCheckCircle,
  faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import XLSX from 'xlsx';
import AppContext from '../context/AppContext';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { fetchPut, fetchPost, fetchGet } from '../utilities/dataCalls';
import React, { useState, useRef, useEffect, useContext } from 'react';
import PacmanLoader from 'react-spinners/PacmanLoader';
import GridLoader from 'react-spinners/GridLoader';
import  AggList  from './aggrementList';
import * as _ from 'underscore';
import { DATAURLS } from '../utilities/constants';

const useStyles = makeStyles((theme) =>
  createStyles({
    dialogRoot: {
      width: '900px',
      height: '450px',
      display: 'flex',
      flexDirection: 'column',
    },
    dialogTitle: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '40px',
      background: 'linear-gradient(to right, #eef2f3, #8e9eab)',
      boxShadow: '1px 1px 3px #8e9eab',
    },
    dialogTitleText: {
      display: 'flex',
      justifyContent: 'space-between',
      fontFamily: "'Poppins'",
      fontWeight: 700,
      textTransform: 'uppercase',
      fontSize: '0.85rem',
    },
    dialogContent: {
      //   marginTop: '10px',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      width: '50%',
      marginBottom: '15px',
      marginRight: '15px',
    },
    button: {
      marginLeft: '15px',
    },
    buttonProgress: { marginLeft: '5px' },
    actionIcon: {
      fontSize: '1.25rem',
      cursor: 'pointer',
    },
    stepContent: {
      width: '100%',
    },
    steps: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      //   paddingLeft: '20px',
    },
    stepsText: {
      marginTop: '15px',
      fontFamily: 'Poppins',
      fontSize: '20px',
    },
    stepsTextError: {
      marginTop: '15px',
      fontFamily: 'Poppins',
      fontSize: '20px',
      color: 'red',
      textAlign: 'center',
    },
    stepsSubText: {
      marginTop: '5px',
      fontFamily: 'Poppins',
      fontSize: '12px',
    },
    uploadArea: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      color: '#ababab',
      '&:hover': {
        color: '#00A591',
        cursor: 'pointer',
      },
    },
    uploadIcon: {
      fontSize: '80px',
      // color: '#cdcdcd',
      // '&:hover': {
      //   color: '#00A591',
      //   cursor: 'pointer',
      // },
    },
    successIcon: {
      color: '#00A591',
      marginBottom: '15px',
    },
    errorIcon: {
      color: '#eb8034',
      marginBottom: '15px',
    },
    successArea: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    spinnerArea: {
      marginBottom: '10px',
    },
    animatedItem: {
      animation: `$myEffect 300ms ${theme.transitions.easing.easeInOut}`,
    },
    '@keyframes myEffect': {
      '0%': {
        opacity: 0,
      },
      '100%': {
        opacity: 1,
      },
    },
  })
);

const AggrementListDialog = ({
  open,
  title,
  allAssets,
  setOpen,
  leaseAggrementNo
}) => {
  const classes = useStyles();
  const appContext = useContext(AppContext);
  // const [leaseAggrementNo, setLeaseAggrementNo] = useState(leaseAggrementNo);
  const [assetData, setAssetData] = useState([]);

  useEffect(() => {
    // Fetching data
    if (open) {
      fetchGet(DATAURLS.ASSETS.url,appContext.token,leaseAggrementNo)
      .then((response) => {
        if (leaseAggrementNo && response.assets && response.assets.length > 0) {
          let seletedAddOnItems = response.assets.filter((item) => {
            return (item.leasing_agreement_no === leaseAggrementNo) 
          });
          setAssetData(seletedAddOnItems)
        }
      })
      .catch((err) => {
        throw err;
      });
    } else {
      setAssetData([])
    }
      
    // let defaultSelectedOnItemsAmt = allAssets.reduce((s, f) => {
    //   return s+ parseInt(f);
    // }, 0);
    
  }, [open, leaseAggrementNo]);

  return (
    <Dialog
      open={open}
      //   onClose={getNewData(p)}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      maxWidth='lg'
      //   className={classes.dialogRoot}
    >
      <div className={classes.dialogRoot}>
        <DialogTitle id='alert-dialog-title' className={classes.dialogTitle}>
          <div className={classes.dialogTitleText}>
            <div>{title} - {leaseAggrementNo}</div>
          </div>
          <FontAwesomeIcon
            icon={faTimes}
            className={classes.actionIcon}
            onClick={() => setOpen(false)}
          />
        </DialogTitle>

        <DialogContent className={classes.dialogContent}>
          <div className="col-md-4 center">
            </div>
          <div className="App">
            <AggList allAssets={assetData} leaseAggrementNo={leaseAggrementNo}/>
          </div>
        </DialogContent>
      </div>
    </Dialog>
  );
};

export default AggrementListDialog
