import React, { useState, useEffect } from 'react';
import { authenticate } from '../utilities/authenticate';
import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import { AppTheme } from '../utilities/Theme';
import { DATAURLS } from '../utilities/constants';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '95vw',
      height: '93vh',
      marginLeft: '4vw',
      // marginTop: '80px',
    },
    title:{
      fontSize: '20px',
      fontWeight: 'bold',
      paddingBottom: '20px'
    },
    section: {
      display: 'flex',
    },
    usertitle: {
      fontSize: '16px',
      paddingRight: '20px'
    },
    body: {
      width: '350px'
    },
    useremail: {
      fontSize: '16px',
      fontWeight: 'bold'
    }
  })
);
const Profile = (props) => {
  const theme = useTheme(AppTheme);
  const classes = useStyles(theme);
  
  const [currentUser, setCurrentUser] = useState({});
  useEffect(() => {
    authenticate()
      .then((res) => {
        console.log("userrr", res);
        // if (res.currentUser.user_name) {
        //   setIsUserLoggedIn(true);
          setCurrentUser(res.currentUser);
        //   setToken(res.token);
        // } else {
        //   setIsUserLoggedIn(false);
        //   setCurrentUser({});
        //   setToken('');
        // }
        // setIsVerified(true);
      })
      .catch((err) => {
        // setIsUserLoggedIn(false);
        // setCurrentUser({});
        // setToken('');
        // setIsVerified(true);
        console.log('error in authenticating');
        throw err;
      });
  }, []);

  return (
    <>
      <div className={classes.root}>
        <div className={classes.body}>
        <span className={classes.title}>Profile</span>

          <div className={classes.section}>
            <div className={classes.usertitle}>User Name :</div>
            <div  className={classes.useremail}>{currentUser.user_name}</div>
          </div>
          <div className={classes.section}>
            <div className={classes.usertitle}>User E-Mail :</div>
            <div  className={classes.useremail}>{currentUser.user_email}</div>
          </div> 
        </div>
     
      </div>
    </>
  );
};

export default Profile;
