import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDownload,
  faUpload,
} from '@fortawesome/free-solid-svg-icons';

import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import * as _ from 'underscore';

import * as moment from 'moment';

import XLSX from 'xlsx';

import clsx from 'clsx';

import { useState, useEffect, useContext, useRef } from 'react';

import { AgGridReact, AgGridColumn } from 'ag-grid-react';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

import ActionCellRenderer from '../components/ActionCellRenderer';
import BulkUpdateDialog from '../components/BulkUpdateDialog';
import CurrencyEditor from '../components/CurrencyEditor';
import CustomDialog from '../components/CustomDialog';
import CustomDropdown from '../components/CustomDropdown';
import DialogEditor from '../components/DialogEditor';
import FileUploadDialog from '../components/FileUploadDialog';
import NumericEditor from '../components/NumericEditor';
import AppContext from '../context/AppContext';
import { AppTheme } from '../utilities/Theme';
import { DATAURLS } from '../utilities/constants';
import {
  fetchPut,
  fetchPost,
  fetchGet,
  fetchDelete,
} from '../utilities/dataCalls';
import { generateExcel } from '../utilities/generateExcel';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '95vw',
      height: '93vh',
      marginLeft: '4vw',
      // marginTop: '80px',
    },

    buttonBox: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '50%',
    },
    buttonArea: {
      display: 'flex',
    },
    select: {
      color: 'white',
    },
    sectionHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      // color: '#212121',
      color: 'white',
      fontWeight: 'bold',
      fontSize: '1rem',
      // marginTop: '20px',
      width: '95%',
      height: '40px',
      boxShadow: '0px 0px 5px #222',
      paddingLeft: '10px',
      background:
      'linear-gradient(90deg, rgba(0,165,145,1) 55%, rgba(0,165,145,1) 100%)',
    },
    actionArea: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-evenly',
      width: '30%',
    },
    actionIconDisabled: {
      color: '#aaa',
      cursor: 'not-allowed',
    },
    actionIcon: {
      fontSize: '1rem',
      cursor: 'pointer',
      color: 'white',
    },

    textRoot: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: 400,
      height: '30px',
    },
    input: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
    title: {
      fontWeight: 'bold',
      paddingRight: '10px',
      paddingBottom: '10px',
      fontSize: '14px',
      color: '#000000b0'
    },
    value: {
      fontSize: '14px',
      fontWeight: 'bold',
    },
    total:{
      display: 'flex',
      width: '95vw',
      marginLeft: '7vw',
      marginBottom: '20px'
    },
    total1 : {
      paddingRight: '20px'
    }
  })
);

const frameworkComponents = {
  //   CustomCellEditor: CustomCellEditor,
  ActionCellRenderer,
  NumericEditor,
  CurrencyEditor,
  DialogEditor,
};

const Assets = () => {
  const theme = useTheme(AppTheme);
  const classes = useStyles(theme);
  const appContext = useContext(AppContext);
  const overlayLoadingTemplate =
    '<span class="ag-overlay-loading-center">Please wait while update in progress</span>';

  const buildColumnDefinitions = (columnDefs, assetTypes) => {
    return columnDefs.map((columnDef, index) => {
      if (columnDef.type === 'date') {
        // console.log({ columnDef });
      }
      let columnDefinition = {
        headerName: index !== 0 ? columnDef.header_name : '',
        cellRenderer: index === 0 ? 'ActionCellRenderer' : false,

        cellRendererParams: {
          onRowEditingStopped: (params) => onRowEditingStopped(params),
        },
        headerCheckboxSelection: index === 0 ? true : false,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: index === 0 ? true : false,
        field: columnDef.field,
        editable: columnDef.editable,
        filter: index !== 0 ? 'agTextColumnFilter' : 'none',
        sortable: true,
        resizable: true,
        hide: columnDef.hide,
        // aggFunc: 'sum',
        width: index === 0 ? 100 : 'auto',

        valueFormatter: (params) => {
          if (columnDef.type === 'currencyColumn') {
            return params.value > 0 ? numberWithCommas(params.value) + ' kr' : '0';
          }

          if (columnDef.type === 'date') {
            return params.value ? moment(params.value).format('YY-MM-DD') : '-';
          }
          if (columnDef.type === 'decimal') {
            return params.value ? parseFloat(params.value).toFixed(2) + ' %'  : '0';
          }
        },
      };
      return columnDefinition;
    });
  };

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }
  
  const [loading, setLoading] = useState(true);
  const [pageSize, setPageSize] = useState(25);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [snackBarType, setSnackBarType] = useState('success');
  const [rowData, setRowData] = useState([]);
  const [rowDataAPI, setRowDataAPI] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const [assetTypeFieldMapping, setAssetTypeFieldMapping] = useState([]);
  const [statusNames, setStatusNames] = useState([]);
  const [palletNumbers, setPalletNumbers] = useState([]);
  const [selectedAssetType, setSelectedAssetType] = useState('All');
  const [fileUploadOpen, setFileUploadOpen] = useState(false);
  const [gridApi, setGridApi] = useState();
  const [quickFilterText, setQuickFilterText] = useState('');
  const externalFilterRef = useRef(null);
  const [rentPerMonth, setRentPerMonth] = useState(0)
  const [invoicedAmount, setInvoicedAmount] = useState(0)
  const [aPrisAmount, setAPrisAmount] = useState(0)

  useEffect(() => {
    if (!gridApi) {
      return;
    }
    let allColumnIds = gridApi.columnController.gridColumns.map(
      (col) => col.colId
    );
    let currentMapping = assetTypeFieldMapping.find(
      (mapping) => mapping.Asset_Name === selectedAssetType
    );

    let columnsToShow = currentMapping ? currentMapping.Fields : [];

    if (!columnsToShow || columnsToShow.length === 0) {
      gridApi.columnController.setColumnsVisible(allColumnIds, true);
      return;
    }

    if (columnsToShow.length > 0) {
      columnsToShow.push('actions', 'asset_id');
    }

    gridApi.columnController.setColumnsVisible(allColumnIds, false);
    gridApi.columnController.setColumnsVisible(columnsToShow, true);
  }, [gridApi, assetTypeFieldMapping, selectedAssetType]);

  useEffect(() => {
    gridApi && gridApi.onFilterChanged();
  }, [selectedAssetType]);

  useEffect(() => {
    setLoading(false);
  }, [rowData]);

  useEffect(() => {
    if (!gridApi) {
      return;
    }

    loading ? gridApi.showLoadingOverlay() : gridApi.hideOverlay();
  }, [loading]);

  const onModelUpdated = (params) => {
    // params.api.sizeColumnsToFit();
  };

  const getNewData = async (gridApi) => {
    setLoading(true);
    gridApi.showLoadingOverlay();

    // Fetching data
    fetchGet(DATAURLS.ASSETS.url, appContext.token)
      .then((response) => {
        setRowData(response.assets);    
        let rent_per_month = 0;
        let invoiced_amount = 0;
        let a_pris__amount = 0;
        if (response.assets && response.assets.length > 0) {
            rent_per_month = response.assets.reduce((s, f) => {
                // console.log("valueee", f.rent_per_month)
                return s+ (f.rent_per_month && !_.isNull(f.rent_per_month) ? parseInt(f.rent_per_month) : 0);
              }, 0);
            invoiced_amount = response.assets.reduce((s, f) => {
                // console.log("valueee", f.invoiced_amount)
                return s+ (f.invoiced_amount ? parseInt(f.invoiced_amount) : 0);
            }, 0);
            a_pris__amount = response.assets.reduce((s, f) => {
              // console.log("valueee", f.invoiced_amount)
              return s+ (f.a_pris ? parseInt(f.a_pris) : 0);
            }, 0);
            setRentPerMonth(rent_per_month);
            setInvoicedAmount(invoiced_amount);
            setAPrisAmount(a_pris__amount);
        }
        // setLoading(false);
        // let tempAPI = JSON.parse(JSON.stringify(response.assets));
        // setRowDataAPI(tempAPI);
      })
      .catch((err) => {
        throw err;
      });

      // fetchGet(DATAURLS.ASSETSREPORT.url, appContext.token)
      // .then((response) => {
      //   console.log("response", response)
      //   // setRowData(response.assets);
      //   // setLoading(false);
      //   // let tempAPI = JSON.parse(JSON.stringify(response.assets));
      //   // setRowDataAPI(tempAPI);
      // })
      // .catch((err) => {
      //   throw err;
      // });
      
    // Fetching column definition
    fetchGet(DATAURLS.COLUMNDEFINITIONS.url, appContext.token)
      .then((response) => {
        setColumnDefs(response.columnDefinitions);
      })
      .catch((err) => {
        throw err;
      });
    highlightUnsavedRows();
  };

  const validateRow = (params) => {
    let allRows = [];
    params.api.forEachNode((node) => allRows.push(node.data));

    let duplicateNumberRows = [];

    duplicateNumberRows = allRows.filter(
      (row) => row.asset_number && row.asset_number === params.data.asset_number
    );

    return duplicateNumberRows.length <= 1;
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
    getNewData(params.api);
  };

  const onRowEditingStopped = (params) => {
    gridApi.stopEditing();

    if (validateRow(params) === true) {
      // setCustomDialogHandleAgree(()=>{})
      // setCustomDialogHandleDisagree(()=>{})

      let currentRowFromAPI = rowDataAPI.find(
        (row) => row.id === params.data.id
      );
        console.log("currentRowFromAPI", currentRowFromAPI)
      if (
        currentRowFromAPI &&
        JSON.stringify(params.data) === JSON.stringify(currentRowFromAPI)
      ) {
        return;
      }

      // if (params.data.asset_id) {
      //   handleUpdate(params);
      //   return;
      // }

      if (currentRowFromAPI) {
        handleUpdate(params);
        return;
      }
      handleSave(params);
    } else {
      setSnackBarOpen(true);
      setSnackBarMessage('Cannot insert duplicate asset number');
      setSnackBarType('error');
      params.api.startEditingCell({
        rowIndex: params.rowIndex,
        colKey: 'asset_number',
      });
    }
  };

  const handleUpdate = (params) => {
    Object.keys(params.data).forEach((key) => {
      if (!params.data[key]) {
        params.data[key] = null;
        params.data['deleted'] = false;
      }
    });

    setLoading(true);
    gridApi.showLoadingOverlay();

    params.data.last_updated_at = new Date().toISOString();
    params.data.last_updated_by = appContext.currentUser.user_email;

    if (params.data.status === 'Sold' && !params.data.date_nor) {
      params.data.date_nor = new Date().toISOString().slice(0, 10);
    }

    if (params.data.status !== 'Sold' && params.data.date_nor) {
      params.data.date_nor = null;
    }
    fetchPut(
      DATAURLS.CERTUS.url,
      {
        data: params.data,
        matchBy: 'asset_id',
      },
      appContext.token
    )
      .then((response) => {
        if (response.ok) {
          setSnackBarOpen(true);
          setSnackBarMessage(response.message);
          setSnackBarType('success');
          gridApi.redrawRows({ rowNodes: [params.node] });
        } else {
          setSnackBarOpen(true);
          setSnackBarMessage(response.message);
          setSnackBarType('error');
          gridApi.startEditingCell({
            rowIndex: params.rowIndex,
            colKey: 'quantity',
          });
        }
        setLoading(false);
        gridApi.hideOverlay();
        setTimeout(() => {
          highlightUnsavedRows(params);
        }, 600);
      })
      .catch((err) => {
        throw err;
      });
  };

  const handleSave = (params) => {
    setLoading(true);
    gridApi.showLoadingOverlay();
    fetchPost(DATAURLS.ASSETS.url, { data: params.data }, appContext.token)
      .then((response) => {
        if (response.ok) {
          setSnackBarOpen(true);
          setSnackBarMessage(response.message);
          setSnackBarType('success');
          params.data.asset_id = response.rows[0].asset_id;
          gridApi.redrawRows({ rowNodes: [params.node] });
        } else {
          setSnackBarOpen(true);
          setSnackBarMessage(response.message);
          setSnackBarType('error');
          gridApi.startEditingCell({
            rowIndex: params.rowIndex,
            colKey: 'asset_type',
          });
        }
        setLoading(false);
        gridApi.hideOverlay();
        params.node.setSelected(false);
      })
      .catch((err) => {
        setLoading(false);
        throw err;
      });
  };

  const handleExport = () => {
    generateExcel(gridApi, 'stock.xlsx');
  };

  const highlightUnsavedRows = (params) => {
    if (!params || rowDataAPI.length === 0) {
      return;
    }
    let missingRowNodes = params.api.rowModel.rowsToDisplay.filter((row) => {
      if (!row.data.asset_id) {
        return row;
      }
    });

    if (missingRowNodes.length > 0) {
      missingRowNodes.map((node) => {
        if (params.node !== node) {
          node.setSelected(true);
        }
      });
    }
  };

  const handleDelete = async (props) => {
    gridApi.showLoadingOverlay();
    setLoading(true);
    if (!props.data.id) {
      let rowDataCopy = [...rowData];
      rowDataCopy.splice(props.node.rowIndex, 1);
      setRowData(rowDataCopy);
      return;
    }

    fetchDelete(
      DATAURLS.ASSETS.url,
      {
        data: { id: props.data.id },
        matchBy: 'id',
      },
      appContext.token
    )
      .then((res) => {
        if (res.ok) {
          let rowDataCopy = [...rowData];
          rowDataCopy.splice(props.node.rowIndex, 1);
          setRowData(rowDataCopy);
          setLoading(false);
        } else {
          setLoading(false);
          console.log('error', res);
        }
      })
      .catch((err) => {
        console.log('deletion failed', err);
      });

    gridApi.hideOverlay();
  };

  return (
    <>
      <div className={classes.root}>
        <div className={classes.sectionHeader}>
          Asset
          <Paper component='form' className={classes.textRoot}>
            <InputBase
              className={classes.input}
              placeholder='Search Asset'
              inputProps={{ 'aria-label': 'Search Asset' }}
              value={quickFilterText}
              onChange={(event) => {
                event.stopPropagation();
                setQuickFilterText(event.target.value);
              }}
            />
            <IconButton
              // type='submit'
              className={classes.iconButton}
              aria-label='search'
            >
              <SearchIcon />
            </IconButton>
          </Paper>
          <div className={classes.actionArea}>
            <FontAwesomeIcon
              icon={faUpload}
              title='Import'
              className={classes.actionIcon}
              onClick={() => setFileUploadOpen(true)}
            />
          {
            rowData.length > 0 && <FontAwesomeIcon
            icon={faDownload}
            title='Export'
            className={classes.actionIcon}
            onClick={() => handleExport()}
          />
          }
          </div>
        </div>
        <div
          className='ag-theme-balham'
          style={{
            width: '95%',
            height: '80vh',
            boxShadow: '0 1px 15px 1px rgba(69,65,78,.08)',
          }}
        >
          <AgGridReact
            rowData={rowData}
            rowBuffer={500}
            debounceVerticalScrollbar={true}
            columnDefs={buildColumnDefinitions(columnDefs)}
            frameworkComponents={frameworkComponents}
            suppressDragLeaveHidesColumns={true}
            onGridReady={onGridReady}
            rowSelection='multiple'
            overlayLoadingTemplate={overlayLoadingTemplate}
            getNewData={getNewData}
            handleDelete={handleDelete}
            pagination={true}
            enableCellTextSelection={true}
            paginationPageSize={pageSize}
            suppressRowClickSelection={true}
            alwaysShowVerticalScroll={true}
            quickFilterText={quickFilterText}
            onModelUpdated={onModelUpdated}
            suppressFieldDotNotation={true}
            floatingFilter={true}
            ></AgGridReact>
          <CustomDropdown
            options={[25, 50, 100, 500]}
            title={'Page Size'}
            value={pageSize}
            onChange={(value) => {
              setPageSize(value);
              gridApi.paginationSetPageSize(value);
            }}
          />
        </div>
        <FileUploadDialog
          open={fileUploadOpen}
          allAssets={rowData}
          title='file upload'
          setOpen={setFileUploadOpen}
          palletNumbers={palletNumbers}
          statusNames={statusNames}
          getNewData={getNewData}
          parentGridApi={gridApi}
        />
      </div>
      <div className={classes.total}>
        <div className={classes.total1}>
          <span className={classes.title}>Hyra per månad:</span>
          <span  className={classes.value}>{numberWithCommas(rentPerMonth) +' kr'}</span>
        </div>
        <div className={classes.total2}>
          <span className={classes.title}>Fakturerat Belopp:</span>
          <span className={classes.value}>{numberWithCommas(invoicedAmount) +' kr'}</span>
        </div>
      </div>
    </>
  );
};

export default Assets;
