import React from 'react'
import Table from 'react-bootstrap/Table';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import * as _ from 'underscore';
import * as moment from 'moment';
import ExportCSV from './exportExcel';

const useStyles = makeStyles((theme) =>
  createStyles({
    even: {
            background: '#ecf0f1'
        },
    bg: {
        background: '#00a591'
    },
    button: {
        background: '#00a591',
        color: '#fff',
        padding: '5px',
        borderRadius: '8px',
        marginBottom: '10px'
    },
    td: {
        padding: '10px'
    },
    title: {
        fontWeight: 'bold',
        paddingRight: '10px',
        paddingBottom: '10px',
        fontSize: '14px'
    },
    value: {
        fontSize: '14px',
        fontWeight: 'bold',
    },
    th: {
        color: '#FFF',
        padding: '10px'
    }
  })
);
function numberWithCommas(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }
const AggList = ({allAssets, leaseAggrementNo}) => {
    const classes = useStyles();
    // console.log("allAssets", allAssets)
    let rent_per_month = 0;
    let invoiced_amount = 0;
    let a_pris__amount = 0;
    if (allAssets && allAssets.length > 0) {
        rent_per_month = allAssets.reduce((s, f) => {
            // console.log("valueee", f.rent_per_month)
            return s+ (f.rent_per_month && !_.isNull(f.rent_per_month) ? parseInt(f.rent_per_month) : 0);
          }, 0);
        invoiced_amount = allAssets.reduce((s, f) => {
            // console.log("valueee", f.invoiced_amount)
            return s+ (f.invoiced_amount ? parseInt(f.invoiced_amount) : 0);
          }, 0);
        //   a_pris__amount = allAssets.reduce((s, f) => {
        //     // console.log("valueee", f.invoiced_amount)
        //     return s+ (f.a_pris ? parseInt(f.a_pris) : 0);
        //   }, 0);
    }
    //   console.log("total rent per month", a)
    const CustomerRow = (customer,index) => {

        return(
            <>
              <tr key = {index} className={classes.even}>
                <td className={classes.td}> {index + 1} </td>
                <td className={classes.td}>{customer.customer_name || '-'}</td>
                <td className={classes.td}>{customer.customer_number || '-'}</td>
                <td className={classes.td}>{customer.document_number || '-'}</td>
                <td className={classes.td}>{customer.leasing_agreement_no || '-'}</td>
                <td className={classes.td}>{numberWithCommas(customer.rent_per_month) +' kr' || '-'}</td>
                <td className={classes.td}>{numberWithCommas(customer.invoiced_amount) +' kr' || '-'}</td>
                <td className={classes.td}>{customer.invoiced_date ? moment(customer.invoiced_date).format('YY-MM-DD') : '-'}</td>
                <td className={classes.td}>{customer.number || '-'}</td>
                <td className={classes.td}>{customer.name || '-'}</td>
                <td className={classes.td}>{customer.serial_no}</td>
                <td className={classes.td}>{customer.product_group_description || '-'}</td>
                <td className={classes.td}>{customer.quantity || '-'}</td>
                <td className={classes.td}>{customer.a_pris +' kr' || '-'}</td>
                <td className={classes.td}>{customer.delivery_name || '-'}</td>
                <td className={classes.td}>{customer.delivery_address_2 || '-'}</td>
                <td className={classes.td}>{customer.delivery_city || '-'}</td>
                <td className={classes.td}>{customer.delivery_post_code || '-'}</td>
                <td className={classes.td}>{customer.contract_period || '-'}</td>
                <td className={classes.td}>{customer.start_date ? moment(customer.start_date).format('YY-MM-DD') : '-'}</td>
                <td className={classes.td}>{customer.start_date ? moment(customer.end_date).format('YY-MM-DD') : '-'}</td>
                <td className={classes.td}>{customer.tariff + '%' || '-'}</td>
              </tr>
            </>
          )
      }
 	
      const CustomerTable = allAssets.map((cust,index) => CustomerRow(cust,index))

      const tableHeader = <thead className={classes.bgvi}>
                            <tr className={classes.bg}>
                                <th className={classes.th}>#</th>
                                <th className={classes.th}>Kundnamn</th>
                                <th className={classes.th}>KundNr</th>
                                <th className={classes.th}>Dokumentnr</th>
                                <th className={classes.th}>Leasingavtalnr</th>
                                <th className={classes.th}>Hyra per månad</th>
                                <th className={classes.th}>Fakturerat Belopp</th>
                                <th className={classes.th}>Fakturadatum</th>
                                <th className={classes.th}>Nr</th>
                                <th className={classes.th}>Benämning</th>
                                <th className={classes.th}>Serienr</th>
                                <th className={classes.th}>ProduktgruppBeskrivning</th>
                                <th className={classes.th}>Antal</th>
                                <th className={classes.th}>A-pris</th>
                                <th className={classes.th}>Leveransnamn</th>
                                <th className={classes.th}>Leveransadress2</th>
                                <th className={classes.th}>Leveransort</th>
                                <th className={classes.th}>Leveranspostnrkod</th>
                                <th className={classes.th}>Avtalstid</th>
                                <th className={classes.th}>Startdatum</th>
                                <th className={classes.th}>Slutdatum</th>
                                <th className={classes.th}>Tariff</th>
                            </tr>
                        </thead>	
    
    return (
        <>
            <div>
                <span className={classes.title}>Hyra per månad:</span>
                <span  className={classes.value}>{numberWithCommas(rent_per_month) +' kr'}</span>
            </div>
            <div>
                <span className={classes.title}>Fakturerat Belopp:</span>
                <span className={classes.value}>{numberWithCommas(invoiced_amount) +' kr'}</span>
            </div>
            <div>
                <span className={classes.title}>Slutdatum:</span>
                <span className={classes.value}>{allAssets.length > 0 ? (allAssets[0].end_date ? moment(allAssets[0].end_date).format('YY-MM-DD') : '-') : '-'}</span>
            </div>
            {/* <div>
                <span className={classes.title}>A-pris:</span>
                <span className={classes.value}>{numberWithCommas(a_pris__amount) +' kr'}</span>
            </div> */}
            {/* <div>
                <span className={classes.title}>Total Summa:</span>
                <span className={classes.value}>{numberWithCommas((invoiced_amount + rent_per_month)) +' kr'}</span>
            </div> */}
            <div>
                <ExportCSV csvData={allAssets} fileName={leaseAggrementNo} />
            </div>
            <Table responsive>
                {tableHeader}
                <tbody>
                    {CustomerTable}
                    <tr>
                        <td colSpan='5'>Sum:</td>
                        <td className={classes.td}>{numberWithCommas(rent_per_month) +' kr'}</td>
                        <td className={classes.td}>{numberWithCommas(invoiced_amount) +' kr'}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className={classes.td}>{numberWithCommas(a_pris__amount) +' kr'}</td>
                    </tr>
                    {/* <tr>
                        <td colSpan='5'>Total:</td>
                        <td className={classes.td}>{(invoiced_amount + rent_per_month) +' kr'}</td>
                    </tr> */}
                </tbody>
            </Table>
        </>
    )
}
export default AggList;
